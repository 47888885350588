import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import locationsData from '../data/locations.json';
import { BsWhatsapp } from 'react-icons/bs';
import ScrollToTopButton from '../components/ScrollToTopButton';

const LocationPage = () => {
    const { location } = useParams();
    const formatStack = (stack) => {
        if (!stack) return '';
        return stack
            .replace(/-/g, ' ') // Replace hyphens with spaces
            .split(' ')         // Split by space
            .map(word => word.toUpperCase()) // Capitalize each word
            .join(' ');         // Join words with space
    };


    useEffect(() => {
        if (!location) {
            // Clearly redirect to your desired URL
            window.location.href = 'https://sivasoft.in';
        }
    }, [location]);

    if (!location) {
        return null; // clearly return null, nothing will show as page immediately redirects
    }

    const locationData = locationsData.find(
        (loc) =>
            loc.slug.training === location ||
            loc.slug.course === location ||
            loc.slug.classes === location
    );

    if (!locationData) return <div>Page not found</div>;

    let title = locationData.city;
    let types = locationData.type.training;
    let stack = locationData.stack;
    let description = "Learn " + formatStack(locationData.stack) + " (AI) MySQL / Mongo DB Express React Node JS in " + locationData.city + ". Expert training, call 63029 64834. Gain Internship & Placement support. Build real-world projects, ";


    if (locationData.slug.course === location) {
        title = locationData.city;
        types = locationData.type.course;
        stack = locationData.stack;
        description = "Learn " + formatStack(locationData.stack) + " (AI) MySQL / Mongo DB Express React Node JS in " + locationData.city + ". Expert training, call 63029 64834. Gain Internship & Placement support. Build real-world projects, ";

    } else if (locationData.slug.classes === location) {
        title = locationData.city;
        types = locationData.type.classes;
        stack = locationData.stack;
        description = "Learn " + formatStack(locationData.stack) + " (AI) MySQL / Mongo DB Express React Node JS in " + locationData.city + ". Expert training, call 63029 64834. Gain Internship & Placement support. Build real-world projects, ";

    }

    const canonicalUrl = `https://https://online-mern.sivasoft.in/${location}`;
    const schemaData = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": `${locationData.stack} (AI) Redux Next ${types}`,
        "description": description,
        "image": "https://https://online-mern.sivasoft.in/images/online-classroom-mern-training-course-institutes.png",
        "url": canonicalUrl,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "416, 417, Annapurna Block, Aditya Enclave, Near Ameerpet Metro Station",
            "addressLocality": title,
            "addressRegion": title,
            "addressCountry": "IN"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingCount": "219560",
            "bestRating": "5",
            "ratingValue": "4.98",
            "worstRating": "0"
        }
    };

    return (
        <div>
            <Helmet>
                <title>{formatStack(stack)} {types}|Classroom & Online|{title}</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta name="description" content={description} />
                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>
            </Helmet>
            <header id="header-part">
                <div className="header-top">
                    <span className='text-white'>🎉 Celebrating 13+ Years! Anniversary Special Offer: Get 50% OFF on All Courses –
                        Limited Time Only!</span>
                </div>

                <div className="header-logo-support pt-20 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="logo float-lg-left text-center">
                                    <a href="index.html" aria-label="Siva Soft Home Page">
                                        <img src="images/sivasoft-logo.png" alt="Siva Soft Logo" className="sivasoft-logo" />
                                        <span className="sivasoft-text">Siva Soft</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 mt-20 mt-lg-2">
                                <div className="text-center">
                                    <div className="cont">
                                        <p>Need Help? call us free</p>
                                        <span><a href="https://wa.me/916302964834" aria-label="Contact us on WhatsApp"><i
                                            className="bi bi-whatsapp"></i> +91 63029 64834</a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 mt-20 mt-lg-0">
                                <div className="button float-lg-right text-center">
                                    <a
                                        href="https://forms.gle/Yu88jfrTMjva8Xxd9"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="main-btn"
                                        aria-label="Apply for Siva Soft courses"
                                    >
                                        Apply For Course
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="navigation">
                    <div className="container">


                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="container">
                                <a className="navbar-brand" href="index.html">
                                    <img src="images/13-th-anniversary-of-sivasoft.png" style={{ "width": "100px" }}
                                        alt="Sivasoft 13th Anniversary Logo" />
                                </a>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation menu">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://sivasoft.in">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://sivasoft.in/classroom-training-courses-list.html">Classroom</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active"
                                                href="https://sivasoft.in/online-training-courses-list.html">Online</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link"
                                                href="https://sivasoft.in/sivasoft-batch-timings.html">Timings</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link"
                                                href="https://forms.gle/ygt3scEY32hvnrxz6"
                                                target="_blank" rel="noopener noreferrer" aria-label="Apply for Siva Soft courses">Apply For Course</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link"
                                                href="https://forms.gle/7Ame823xF4VK1Xm87"
                                                target="_blank" rel="noopener noreferrer" aria-label="Apply to become a faculty member at Siva Soft">Apply For Faculty</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link"
                                                href="https://sivasoft.in/contact.html">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>


            <img src="/images/online-classroom-mern-training-course-institutes.png" alt="Classroom and Online MERN Training Course" className='w-100' />


            <div className="divider"></div>
            <div className='container'>
                <header>
                    <h3 className="pt-3 pl-3">
                        SIVASOFT TECHNOLOGIES PRIVATE LIMITED - Leading IT Training (2012-{new Date().getFullYear()})
                    </h3>
                    <p className="pl-3">
                        India's premier provider of classroom and online training in cutting-edge technologies.
                    </p>
                </header>
                <div className="row mt-3 pt-3 px-3">
                    <div className="col-md-12">
                        <div className="mt-3">
                            <div className='divider'></div>
                            <div className="button text-center">
                                <a href="https://forms.gle/ygt3scEY32hvnrxz6" target="_blank" rel="noopener noreferrer"
                                    className="main-btn">Apply
                                    For Course</a>
                            </div>
                            <div className='divider'></div>
                            <p>
                                🚀 100% Classroom and Online Job-Oriented Program:
                                <strong className="program-title">

                                    Master Full-Stack MERN (AI & MongoDB / MySQL Express React Js Node Js) with AWS, GCP, and React, and Internship Training Course
                                </strong>
                            </p>
                            <div className='divider'></div>

                            <div aria-label="Full Stack MERN training program pricing information">
                                <p
                                    aria-label="Full Stack MERN group training fee: original price 20,000 rupees, offer price 10,000 rupees.">
                                    <strong>Group Training Fee:</strong> <s className="original-price">₹20,000/-</s>
                                    <span className="offer-separator">➡️ Offer:</span>
                                    <span className="offer-price">₹10,000/-</span>
                                </p>

                                <p
                                    aria-label="Full Stack MERN one-on-one training fee: original price 1,00,000 rupees, offer price 50,000 rupees.">
                                    <strong>One-On-One Training Fee:</strong> <s
                                        className="original-price">₹1,00,000/-</s>
                                    <span className="offer-separator">➡️ Offer:</span>
                                    <span className="offer-price">₹50,000/-</span>
                                </p>

                                <p className="mb-3"
                                    aria-label="Full Stack MERN training program duration: 6 months of training and 1 month free internship.">
                                    <strong>Duration:</strong> <span className="offer-price">6 Months Training + 1 Month
                                        Free Internship</span>
                                </p>
                            </div>

                            <section id="internships">
                                <h2 className="text-center mb-4"
                                    aria-label="Types of Internships for Full Stack MERN Developers">
                                    📢 Types of Internships for Full Stack MERN Developers
                                </h2>
                                <p className="text-center lead"
                                    aria-label="We offer three types of internships in collaboration with 15+ companies in Hyderabad.">
                                    We offer <strong>three types of internships</strong> in collaboration with
                                    <strong>15+ companies</strong> in Hyderabad, designed to help aspiring
                                    <strong>Full Stack MERN developers</strong> gain hands-on industry experience.
                                </p>

                                <div className="row mt-4">
                                    {/* Paid Internship  */}
                                    <div className="col-md-4 mb-2">
                                        <div className="card shadow-lg border-0 h-100">
                                            <div className="card-body">
                                                <h3 className="card-title text-primary"
                                                    aria-label="Paid Internship for Full Stack MERN Developers">💰
                                                    Paid Internship</h3>
                                                <p className="card-text">Structured internship with direct industry
                                                    exposure.</p>
                                                <ul className="list-unstyled">
                                                    <li>✅ <strong>Company Tie-Ups:</strong> Work with top companies
                                                        in Full Stack MERN.</li>
                                                    <li>💵 <strong>Fees:</strong> ₹20,000 - ₹50,000</li>
                                                    <li>🎯 <strong>Benefits:</strong> Hands-on projects, mentorship
                                                        & job opportunities.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Stipend-Based Internship --> */}
                                    <div className="col-md-4 mb-2">
                                        <div className="card shadow-lg border-0 h-100">
                                            <div className="card-body">
                                                <h3 className="card-title text-success"
                                                    aria-label="Stipend-Based Internship for Full Stack MERN Developers">
                                                    💸 Stipend-Based Internship</h3>
                                                <p className="card-text">Get paid while learning real-world Full Stack MERN skills.</p>
                                                <ul className="list-unstyled">
                                                    <li>✅ <strong>Performance-Based Selection:</strong> Via
                                                        interviews.</li>
                                                    <li>💰 <strong>Stipend:</strong> ₹5,000 - ₹10,000/month</li>
                                                    <li>📈 <strong>Post-Internship Salary:</strong> ₹15,000 -
                                                        ₹30,000/month</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Free Internship --> */}
                                    <div className="col-md-4 mb-2">
                                        <div className="card shadow-lg border-0 h-100">
                                            <div className="card-body">
                                                <h3 className="card-title text-danger"
                                                    aria-label="Free Internship at SivaSoft for Full Stack MERN Developers">
                                                    🎓 Free Internship at SivaSoft</h3>
                                                <p className="card-text">Perfect for freshers looking to gain experience
                                                    at no cost.</p>
                                                <ul className="list-unstyled">
                                                    <li>✅ <strong>Duration:</strong> 1 Month</li>
                                                    <li>🏆 <strong>Includes:</strong> Real-world Full Stack MERN
                                                        training.</li>
                                                    <li>🚀 <strong>Build industry-relevant projects.</strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <a href="https://forms.gle/Yu88jfrTMjva8Xxd9" target="_blank"
                                        rel="noopener noreferrer" className="btn btn-primary btn-lg">
                                        🌐 Apply Now at SivaSoft
                                    </a>
                                </div>
                            </section>

                            <section aria-label="Career Opportunities Comparison">
                                <h2 className="dark-header">Career Opportunities</h2>
                                <p>Comparison of Front-End and Full-Stack MERN React Developer Roles</p>
                                <div className="table-responsive">
                                    <table aria-label="Career Opportunities Comparison Table"
                                        className="table table-bordered table-stripped">
                                        <caption>
                                            Comparison of Front-End and Full-Stack MERN React Developer Roles
                                        </caption>
                                        <thead className="custom-thead-bg">
                                            <tr>
                                                <th scope="col">Factor</th>
                                                <th scope="col">Front-End Developer (React, Redux, Next)</th>
                                                <th scope="col">Full-Stack MERN React Developer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Job Openings</th>
                                                <td>High, but mostly local</td>
                                                <td>Very High, many H-1B opportunities</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Salary (Onsite USA)</th>
                                                <td>$100k - $180k</td>
                                                <td>$120k - $200k+</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>


                            <div className="row mt-3 mb-3">
                                {/* <!-- Left Column --> */}
                                <div className="col-md-6">
                                    <h3 className="text-primary">📚 Training & Eligibility</h3>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">🏫 <strong>Training Mode:</strong> Classroom
                                            & Online</li>
                                        <li className="list-group-item">🎓 <strong>Eligibility:</strong> Final Year
                                            / Any Graduates / Career Gap</li>
                                        <li className="list-group-item">👨‍🏫 <strong>Trainer (120+
                                            Batches):</strong> Mr. Siva (15+ Yrs) / Mr. Ravi (12+ Yrs)</li>
                                        <li className="list-group-item">🌟 <strong>Offer:</strong> Valid only for 7
                                            days</li>
                                        <li className="list-group-item">⏰ <strong>Daily Lab Access:</strong>
                                            Unlimited</li>
                                        <li className="list-group-item">🏢 <strong>Hiring Partners:</strong> 250+
                                            Companies Hiring from SivaSoft</li>
                                        <li className="list-group-item"
                                            aria-label="Technical training on Full Stack MERN with hands-on projects">
                                            💻 <strong>Technical Training:</strong> Hands-on Full Stack MERN
                                            Development
                                        </li>
                                    </ul>
                                </div>

                                {/* <!-- Right Column --> */}
                                <div className="col-md-6">
                                    <h3 className="text-success">🎓 Certification & Career Support</h3>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">📜 <strong>Certificates:</strong> Free
                                            Course Completion & Internship Certificates</li>
                                        <li className="list-group-item">💼 <strong>Placement Support:</strong> 100%
                                            Until You Get a Job</li>
                                        <li className="list-group-item">💸 <strong>Salary Range:</strong> 2.5 – 60
                                            LPA</li>
                                        <li className="list-group-item">🌐 <strong>Industry Terms:</strong> Agile,
                                            Scrum, DevOps, Monitoring, etc.</li>
                                        <li className="list-group-item">💡 <strong>Career Tips:</strong> Strategies
                                            to Excel in Companies</li>
                                        <li className="list-group-item">✨ <strong>One-on-One Demo:</strong> Get
                                            Personalized Training & Clarifications</li>
                                        <li className="list-group-item"
                                            aria-label="Mock interviews and coding challenges for interview preparation">
                                            📝 <strong>Interview Readiness:</strong> Mock Interviews & Coding
                                            Challenges
                                        </li>
                                    </ul>
                                </div>
                                <div className="row mt-3 mb-3">
                                    {/* <!-- Left Column --> */}
                                    <div className="col-md-6">
                                        <h3 className="text-danger">📚 Learning & Support</h3>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">📚 <strong>Lifetime Access:</strong> Live
                                                Classes & Video Recordings</li>
                                            <li className="list-group-item">❓ <strong>Doubt Clarification:</strong> 7:00
                                                AM - 10:00 PM (Live, WhatsApp, LMS, Zoom, On-site)</li>
                                            <li className="list-group-item">📝 <strong>Assessments:</strong>
                                                Assignments, Quizzes, Interview Questions, 10+ Mock Interviews</li>
                                            <li className="list-group-item">🔗 <strong>Resume & LinkedIn
                                                Optimization:</strong> Job Portals, Portfolio Website</li>
                                            <li className="list-group-item">🛠 <strong>Job Support:</strong> 3 Months
                                                Free (Sat & Sun)</li>
                                        </ul>
                                    </div>

                                    {/* <!-- Right Column: Soft Skills & Additional Training --> */}
                                    <div className="col-md-6">
                                        <h3 className="text-warning">💬 Campus Recruitment Training (CRT)</h3>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item bg-light text-dark"
                                                aria-label="Training fee for Spoken English, Soft Skills, Aptitude, and Reasoning: ₹3000">
                                                💰 <strong>Training Fee:</strong> ₹3000 (Includes All Below)
                                            </li>
                                            <li className="list-group-item" aria-label="Spoken English Training">
                                                🎤 <strong>Spoken English Training:</strong> Included
                                            </li>
                                            <li className="list-group-item" aria-label="Soft Skills Training">
                                                💬 <strong>Soft Skills Training:</strong> Included
                                            </li>
                                            <li className="list-group-item" aria-label="Aptitude Training">
                                                🧠 <strong>Aptitude Training:</strong> Included
                                            </li>
                                            <li className="list-group-item" aria-label="Reasoning Training">
                                                🧮 <strong>Reasoning Training:</strong> Included
                                            </li>

                                        </ul>
                                    </div>
                                </div>


                                <div className="container py-4">
                                    <div className="row mt-3 mb-3">
                                        {/* <!-- Left Column: Capstone Projects --> */}
                                        <div className="col-md-6">
                                            <h3 className="text-warning">💻 8 Capstone Projects</h3>
                                            <p
                                                aria-label="Hands-on Capstone Projects to enhance practical experience in Full-Stack development">
                                                Gain real-world experience with <strong>7 hands-on Capstone
                                                    Projects</strong> covering
                                                <strong>HTML, CSS, JavaScript, Bootstrap, Tailwind, React development.</strong>
                                            </p>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 1: HTML & CSS Basic Project">
                                                    🏗️ <strong>Capstone Project 1:</strong> HTML & CSS Basic
                                                    Website
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 2: Responsive Web Design using HTML5 & CSS3 with Floats">
                                                    📱 <strong>Capstone Project 2:</strong> HTML5 & CSS3 Responsive
                                                    Website with Floats
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 3: Responsive Web Design using HTML5, CSS3, and Flexbox">
                                                    🎨 <strong>Capstone Project 3:</strong> HTML5 & CSS3,4
                                                    Responsive Project with Flexbox
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 4: JavaScript Interactive Web Application">
                                                    ⚡ <strong>Capstone Project 4:</strong> JavaScript Dynamic Web
                                                    App
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 5: Bootstrap and Tailwind CSS Responsive UI">
                                                    🎭 <strong>Capstone Project 5:</strong> Bootstrap & Tailwind CSS
                                                    Responsive Project
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 6: React Front-End Application">
                                                    ⚛️ <strong>Capstone Project 6:</strong> React Front-End
                                                    Development
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 7: Full-Stack React with API Integration">
                                                    🔗 <strong>Capstone Project 7:</strong> React with API
                                                    Integration (Full-Stack)
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone Project 8: MERN">
                                                    🔗 <strong>Capstone Project 8:</strong> MERN (Full-Stack)
                                                </li>
                                            </ul>
                                        </div>

                                        {/* <!-- Right Column: Additional Benefits --> */}
                                        <div className="col-md-6">
                                            <h3 className="text-success">🚀 Capstone Project Benefits</h3>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item"
                                                    aria-label="Real-world projects for job-ready experience">
                                                    🛠️ <strong>Real-World Application:</strong> Work on
                                                    industry-relevant projects.
                                                </li>

                                                <li className="list-group-item"
                                                    aria-label="Portfolio-ready projects for career growth">
                                                    📂 <strong>Portfolio Building:</strong> Add completed projects
                                                    to your GitHub & resume.
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Capstone projects aligned with job market needs">
                                                    🏢 <strong>Job Market Alignment:</strong> Aligned with hiring
                                                    trends in Full-Stack Development.
                                                </li>
                                                <li className="list-group-item"
                                                    aria-label="Guidance and mentor feedback on projects">
                                                    👨‍🏫 <strong>Mentor Guidance:</strong> Expert feedback on
                                                    project implementation.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h4 className="mb-2"> Course Curriculum </h4>
                                    <ul className="left">
                                        <li>1. Fundamentals of Web Technology</li>
                                        <li>2. HTML</li>
                                        <li>3. XHTML</li>
                                        <li>4. CSS</li>
                                        <li>5. CSS 3,4</li>
                                        <li>6. Basic & Advanced
                                            Javascript</li>
                                        <li>7. Async / Await</li>
                                        <li>8. Ajax</li>
                                        <li>9. JSON</li>
                                        <li>10. HTML5</li>
                                        <li>11. Bootstrap / Tailwind CSS</li>
                                        <li>12. Social Media
                                            Integrations</li>
                                        <li>13. Search Engine Optimization</li>
                                        <li>14. Google Analytics Integration</li>
                                        <li>15. Capstone Project 1: HTML & CSS Project</li>
                                        <li>16. Capstone Project 2: HTML5 & CSS3 Responsive Project with Floats</li>
                                        <li>17. Capstone Project 3: HTML5 & CSS3,4 Responsive Project with Flexbox</li>
                                        <li>18. Capstone Project 4: JavaScript Project</li>
                                        <li>19. Capstone Project 5: Bootstrap / Tailwind CSS Responsive Project</li>
                                        <li>20. Domain Registration & Web Hosting</li>
                                        <li>21. ECMAScript</li>
                                        <li>22. TypeScript</li>
                                        <li>23. SASS / SCSS</li>
                                        <li>24. React(JavaScript & TypeScript) / Material UI / Jest / Redux /
                                            Next.js (React Framework)*****</li>
                                        <li>25. CRUD (Create, Read, Update, Delete)</li>
                                        <li>26. RESTful APIs to fetch, send, and manipulate data from the server
                                        </li>
                                        <li>27. JSON Web Token (JWT)</li>
                                        <li>28. User Authentication and Authorization</li>
                                        <li>29. Developer Tools and Debugging</li>
                                        <li>30. AI: ChatGPT, CoPilot, Gemini</li>
                                        <li>31. Postman</li>
                                        <li>32. Figma</li>
                                        <li>33. Version Control & Project Management Tools
                                            <ul className="left">
                                                <li>1. Bitbucket / GitHub (Version Control with Git)</li>
                                                <li>2. Jira</li>
                                                <li>3. Confluence</li>
                                            </ul>
                                        </li>
                                        <li>34. Generative AI</li>
                                        <li>35. Capstone Project 7: React</li>
                                        <li>36. Deployment: GCP / AWS Cloud / Jenkins</li>
                                        <li>37. Node.js</li>
                                        <li>38. Express JS</li>
                                        <li>39. MongoDB</li>
                                        <li>40. MySQL</li>
                                        <li>41. Capstone Project 8: MERN</li>
                                    </ul>
                                    <div className="divider"></div>

                                    <h6 className="mt-2">1. Fundamentals of Web Technology</h6>
                                    <ul className="left">
                                        <li>Network</li>
                                        <li>Internet</li>
                                        <li>Web Browsers</li>
                                        <li>Web Servers</li>
                                        <li>Web</li>
                                        <li>Webpage</li>
                                        <li>Website</li>
                                        <li>Http</li>
                                        <li>Https</li>
                                        <li>Web Development</li>
                                    </ul>
                                    <h6 className="mt-2">2. HTML</h6>
                                    <ul className="left">
                                        <li>Introduction
                                            <ul className="left">
                                                <li>W3C</li>
                                                <li>Editors</li>
                                                <li>Tag
                                                    <ul className="left">
                                                        <li>Empty Tag</li>
                                                        <li>Container Tag</li>
                                                    </ul>
                                                </li>
                                                <li>Attributes & Parameters</li>
                                                <li>Doctypes: DTD</li>
                                                <li>Structure Of HTML</li>
                                                <li>Basic Tags
                                                    <ul className="left">
                                                        <li>html</li>
                                                        <li>head</li>
                                                        <li>title</li>
                                                        <li>body</li>
                                                    </ul>
                                                </li>
                                                <li>Tag vs Element</li>
                                                <li>Headings</li>
                                                <li>Formatting Tags</li>
                                                <li>Paragraph tag</li>
                                                <li>Pre tag</li>
                                                <li>abbr tag</li>
                                                <li>List
                                                    <ul className="left">
                                                        <li>Ordered List</li>
                                                        <li>Unordered List</li>
                                                        <li>Nested List</li>
                                                        <li>Description List</li>
                                                    </ul>
                                                </li>
                                                <li>Iframe</li>
                                                <li>hr</li>
                                                <li>table</li>
                                                <li>JPG / PNG / GIF</li>
                                                <li>Image tag
                                                    <ul className="left">
                                                        <li>File Paths</li>
                                                    </ul>
                                                </li>
                                                <li>Links
                                                    <ul className="left">
                                                        <li>Image Link</li>
                                                        <li>Text Link</li>
                                                        <li>Email Link</li>
                                                    </ul>
                                                </li>
                                                <li>Quotation</li>
                                                <li>Citation</li>
                                                <li>Comments</li>
                                                <li>Colors</li>
                                                <li>Computer Code</li>
                                                <li>Entities</li>
                                                <li>Symbols</li>
                                                <li>Emojis</li>
                                                <li>Charset</li>
                                                <li>URL Encode</li>
                                                <li>Meta Tags</li>
                                                <li>Form tags</li>
                                                <li>Style Guide</li>
                                                <li>Deprecated tags
                                                    <ul className="left">
                                                        <li>center</li>
                                                        <li>font</li>
                                                        <li>basefont</li>
                                                        <li>s</li>
                                                        <li>strike</li>
                                                        <li>u</li>
                                                        <li>applet</li>
                                                        <li>isindex</li>
                                                        <li>acronym</li>
                                                        <li>frame</li>
                                                        <li>frameset</li>
                                                        <li>noframes</li>
                                                        <li>embed</li>
                                                        <li>marquee</li>
                                                        <li>big</li>
                                                        <li>dir</li>
                                                        <li>spacer</li>
                                                        <li>tt</li>
                                                    </ul>
                                                </li>
                                                <li>Deprecated Attributes
                                                    <ul className="left">
                                                        <li>align - caption, iframe, img, input, legend, table, hr,
                                                            div,
                                                            h1,
                                                            h2, h3, h4, h5, h6, p,
                                                            td, th, and tr.</li>
                                                        <li>hspace - img</li>
                                                        <li>vspace - img</li>
                                                        <li>width - hr, table, td, th, pre, img.</li>
                                                        <li>height - table, td, th, pre, img.</li>
                                                        <li>background - body</li>
                                                        <li>bgcolor - table, tr, td, th and body.</li>
                                                        <li>rules - table</li>
                                                        <li>border - table, img</li>
                                                        <li>cellpadding - table</li>
                                                        <li>cellspacing - table</li>
                                                        <li>size - hr</li>
                                                        <li>type - li, ol and ul</li>
                                                        <li>noshade - hr</li>
                                                        <li>char</li>
                                                        <li>charoff</li>
                                                        <li>color, face - font</li>
                                                        <li>marginwidth, marginheight -
                                                            iframe, body</li>
                                                        <li>nowrap - td,th</li>
                                                        <li>size - font,hr</li>
                                                        <li>start - ol</li>
                                                        <li>valign - td, th, tr</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h6 className="mt-2">3. XHTML</h6>

                                    <ul className="left">
                                        <li>XHTML</li>
                                        <li>Differences between HTML and XHTML</li>
                                    </ul>

                                    <h6 className="mt-2">4. CSS</h6>
                                    <ul className="left">
                                        <li>Introduction</li>
                                        <li>Syntax</li>
                                        <li>Types of StyleSheets
                                            <ul className="left">
                                                <li>Internal StyleSheets</li>
                                                <li>External StyleSheets</li>
                                                <li>Inline StyleSheets</li>
                                                <li>Compound StyleSheet</li>
                                            </ul>
                                        </li>
                                        <li>!important</li>
                                        <li>Types of Selectors
                                            <ul className="left">
                                                <li>Simple
                                                    <ul className="left">
                                                        <li>Universal</li>
                                                        <li>Element</li>
                                                        <li>Class</li>
                                                        <li>Element.className</li>
                                                        <li>Id</li>
                                                        <li>Element.id</li>
                                                        <li>Grouping</li>
                                                    </ul>
                                                </li>
                                                <li>Combinators
                                                    <ul className="left">
                                                        <li>Descendant</li>
                                                        <li>Child</li>
                                                        <li>Adjacent</li>
                                                        <li>General</li>
                                                    </ul>
                                                </li>
                                                <li>Pseudo-className(Links)</li>
                                                <li>Pseudo-elements</li>
                                                <li>Attribute</li>
                                            </ul>
                                        </li>
                                        <li>Block & Inline</li>
                                        <li>inline-block</li>
                                        <li>Div's & Span tags</li>
                                        <li>display</li>
                                        <li>Visibility</li>
                                        <li>Units</li>
                                        <li>Box Model
                                            <ul className="left">
                                                <li>Border
                                                    <ul className="left">
                                                        <li>border</li>
                                                        <li>border-color</li>
                                                        <li>border-style</li>
                                                        <li>border-width</li>
                                                        <li>border-bottom
                                                            <ul className="left">
                                                                <li>border-bottom-color</li>
                                                                <li>border-bottom-style</li>
                                                                <li>border-bottom-width</li>
                                                            </ul>
                                                        </li>
                                                        <li>border-left
                                                            <ul className="left">
                                                                <li>border-left-color</li>
                                                                <li>border-left-style</li>
                                                                <li>border-left-width</li>
                                                            </ul>
                                                        </li>
                                                        <li>border-right
                                                            <ul className="left">
                                                                <li>border-right-color</li>
                                                                <li>border-right-style</li>
                                                                <li>border-right-width</li>
                                                            </ul>
                                                        </li>
                                                        <li>border-top
                                                            <ul className="left">
                                                                <li>border-top-color</li>
                                                                <li>border-top-style</li>
                                                                <li>border-top-width</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>outline
                                                    <ul className="left">
                                                        <li>outline-style</li>
                                                        <li>outline-width</li>
                                                        <li>outline-color</li>
                                                    </ul>
                                                </li>
                                                <li>margin
                                                    <ul className="left">
                                                        <li>margin-bottom</li>
                                                        <li>margin-left</li>
                                                        <li>margin-right</li>
                                                        <li>margin-top</li>
                                                    </ul>
                                                </li>
                                                <li>padding
                                                    <ul className="left">
                                                        <li>padding-bottom</li>
                                                        <li>padding-left</li>
                                                        <li>padding-right</li>
                                                        <li>padding-top</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Font
                                            <ul className="left">
                                                <li>font-family</li>
                                                <li>color</li>
                                                <li>font-size</li>
                                                <li>Set Font Size With Pixels</li>
                                                <li>Set Font Size With em</li>
                                                <li>font-style</li>
                                                <li>font-variant</li>
                                                <li>font-weight</li>
                                                <li>font</li>
                                            </ul>
                                        </li>



                                        <li>Text
                                            <ul className="left">
                                                <li>Color</li>
                                                <li>letter-spacing</li>
                                                <li>Word-spacing</li>
                                                <li>line-height</li>
                                                <li>text-align</li>
                                                <li>text-align-last</li>
                                                <li>text-decoration
                                                    <ul className="left">
                                                        <li>text-decoration-line</li>
                                                        <li>text-decoration-color</li>
                                                        <li>text-decoration-style</li>
                                                        <li>text-decoration-thickness</li>
                                                    </ul>
                                                </li>
                                                <li>text-indent</li>
                                                <li>text-transform</li>
                                                <li>vertical-align</li>
                                                <li>White-space</li>
                                                <li>Direction</li>
                                                <li>unicode-bidi</li>
                                            </ul>
                                        </li>
                                        <li>Background
                                            <ul className="left">
                                                <li>background-color</li>
                                                <li>background-image</li>
                                                <li>background-repeat</li>
                                                <li>background-attachment</li>
                                                <li>background-position</li>
                                                <li>background</li>
                                            </ul>
                                        </li>
                                        <li>Image Sprites</li>
                                        <li>Comments</li>
                                        <li>Icons</li>
                                        <li>Images</li>
                                        <li>opacity</li>
                                        <li>FORMS</li>
                                        <li>Links
                                            <ul className="left">
                                                <li>a:link</li>
                                                <li>a:visited</li>
                                                <li>a:hover</li>
                                                <li>a:active</li>
                                            </ul>
                                        </li>
                                        <li>cursor</li>
                                        <li>Lists
                                            <ul className="left">
                                                <li>list-style</li>
                                                <li>list-style-type</li>
                                                <li>list-style-position</li>
                                                <li>list-style-image</li>
                                            </ul>
                                        </li>
                                        <li>Tables
                                            <ul className="left">
                                                <li>border</li>
                                                <li>border-collapse</li>
                                                <li>width</li>
                                                <li>height</li>
                                                <li>text-align</li>
                                                <li>vertical-align</li>
                                                <li>padding</li>
                                            </ul>
                                        </li>
                                        <li>Dimension
                                            <ul className="left">
                                                <li>height</li>
                                                <li>width</li>
                                                <li>max-height</li>
                                                <li>min-height</li>
                                                <li>max-width</li>
                                                <li>min-width</li>
                                            </ul>
                                        </li>
                                        <li>Positioning
                                            <ul className="left">
                                                <li>bottom</li>
                                                <li>clip</li>
                                                <li>left</li>
                                                <li>overflow</li>
                                                <li>absolute</li>
                                                <li>fixed</li>
                                                <li>relative</li>
                                                <li>static</li>
                                                <li>right</li>
                                                <li>top</li>
                                                <li>z-index</li>
                                                <li>sticky</li>
                                            </ul>
                                        </li>
                                        <li>float & clear</li>
                                        <li>Counters</li>
                                        <li>Specificity</li>
                                        <li>Math functions</li>
                                        <li>Navigation (Menu) Bar</li>
                                        <li>Dropdown Navigation Bar</li>
                                        <li>Capstone Project 1: HTML & CSS</li>
                                    </ul>


                                    <h6 className="mt-2">5. CSS 3,4</h6>

                                    <ul className="left">
                                        <li>Introduction to CSS3</li>
                                        <li>What's New in CSS3 and CSS4</li>
                                        <li>border-image</li>
                                        <li>box-shadow</li>
                                        <li>text-shadow</li>
                                        <li>border-radius</li>
                                        <li>Image Reflection</li>
                                        <li>background
                                            <ul className="left">
                                                <li>background-clip</li>
                                                <li>background-origin</li>
                                                <li>background-size</li>
                                                <li>Multiple Background Images</li>
                                            </ul>
                                        </li>
                                        <li>Color Keywords</li>
                                        <li>Gradients
                                            <ul className="left">
                                                <li>Linear Gradients</li>
                                                <li>Radial Gradients</li>
                                                <li>Conic Gradients</li>
                                            </ul>
                                        </li>
                                        <li>text effects
                                            <ul className="left">
                                                <li>word-wrap</li>
                                                <li>text-overflow</li>
                                                <li>word-break</li>
                                                <li>writing-mode</li>
                                            </ul>
                                        </li>
                                        <li>Web fonts</li>
                                        <li>Google fonts</li>
                                        <li>2D Transforms</li>
                                        <li>3D Transforms</li>
                                        <li>RGBA</li>
                                        <li>Box Sizing</li>
                                        <li>Transitions</li>
                                        <li>Animations</li>
                                        <li>Style Images</li>
                                        <li>object-fit</li>
                                        <li>object-position</li>
                                        <li>Masking</li>
                                        <li>Multiple Columns</li>
                                        <li>User Interface</li>
                                        <li>Variables</li>
                                        <li>Box-sizing</li>
                                        <li>Flex</li>
                                        <li>Media Queries</li>
                                        <li>Orientation</li>
                                        <li>favicon</li>
                                        <li>Grid</li>
                                        <li>Capstone Project 2: HTML5 & CSS3 Responsive Project with Floats</li>
                                        <li>Capstone Project 3: HTML5 & CSS3,4 Responsive Project with Flexbox</li>
                                        <li>Content will be updated as per latest updates</li>
                                    </ul>



                                    <h6 className="mt-2">6. Basic & Advanced
                                        Javascript</h6>

                                    <ul className="left">
                                        <li>Introduction to JavaScript</li>
                                        <li>Syntax</li>
                                        <li>Statements</li>
                                        <li>Types of Scripts
                                            <ul className="left">
                                                <li>Internal</li>
                                                <li>External</li>
                                                <li>Inline</li>
                                            </ul>
                                        </li>
                                        <li>Write / Writeln</li>
                                        <li>innerHTML</li>
                                        <li>innerText</li>
                                        <li>textContent</li>
                                        <li>console</li>
                                        <li>Print</li>
                                        <li>Popup Boxes
                                            <ul className="left">
                                                <li>alert</li>
                                                <li>confirm</li>
                                                <li>prompt</li>
                                            </ul>
                                        </li>
                                        <li>Naming Conventions
                                            <ul className="left">
                                                <li>Lower Camel Case</li>
                                                <li>Upper Camel Case</li>
                                                <li>Snake Case</li>
                                                <li>Kebab Case</li>
                                                <li>Screaming Snake Case</li>
                                                <li>Train Case</li>
                                                <li>Flat Case</li>
                                                <li>Upper Flat Case</li>
                                                <li>Title Case</li>
                                                <li>Sentence Case</li>
                                                <li>Toggle Case</li>
                                            </ul>
                                        </li>
                                        <li>Comments</li>
                                        <li>Variables</li>
                                        <li>Hoisting</li>
                                        <li>Operators
                                            <ul className="left">
                                                <li>Arithmetic</li>
                                                <li>Assignment</li>
                                                <li>String</li>
                                                <li>Type</li>
                                                <li>Comparison</li>
                                                <li>Logical</li>
                                            </ul>
                                        </li>
                                        <li>Conditional Statements
                                            <ul className="left">
                                                <li>if</li>
                                                <li>if...else</li>
                                                <li>if...else if...else</li>
                                                <li>Switch</li>
                                            </ul>
                                        </li>
                                        <li>Loops
                                            <ul className="left">
                                                <li>while</li>
                                                <li>do...while</li>
                                                <li>for</li>
                                                <li>for...in</li>
                                                <li>for...of (ES6)</li>
                                                <li>Break</li>
                                                <li>Continue</li>
                                            </ul>
                                        </li>
                                        <li>Functions
                                            <ul className="left">
                                                <li>User-defined</li>
                                                <li>Syntax</li>
                                                <li>Parameters and Arguments</li>
                                                <li>Returning Values</li>
                                                <li>Self-Invoked</li>
                                            </ul>
                                        </li>

                                        <li>Functions
                                            <ul className="left">
                                                <li>Declaration</li>
                                                <li>Expression</li>
                                                <li>Self-Invoked</li>
                                                <li>this
                                                    <ul className="left">
                                                        <li>call</li>
                                                        <li>apply</li>
                                                        <li>bind</li>
                                                    </ul>
                                                </li>
                                                <li>Closures</li>
                                                <li>Local and Global Variables</li>
                                                <li>Recursive Functions</li>
                                                <li>Built-in Functions
                                                    <ul className="left">
                                                        <li>Global</li>
                                                        <li>Number</li>
                                                        <li>String</li>
                                                        <li>Array</li>
                                                        <li>Math</li>
                                                        <li>Date</li>
                                                        <li>JSON</li>
                                                        <li>Utility</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Events
                                            <ul className="left">
                                                <li>Introduction</li>
                                                <li>Mouse Events</li>
                                                <li>Keyboard Events</li>
                                                <li>Form Events</li>
                                                <li>Document Events</li>
                                                <li>Window Events</li>
                                                <li>Event Bubbling</li>
                                            </ul>
                                        </li>
                                        <li>Page Redirection</li>
                                        <li>Timing Events
                                            <ul className="left">
                                                <li>setTimeout</li>
                                                <li>setInterval</li>
                                            </ul>
                                        </li>
                                        <li>Built-in Objects
                                            <ul className="left">
                                                <li>Introduction</li>
                                                <li>Arrays</li>
                                                <li>Array Properties and Methods</li>
                                                <li>Number</li>
                                                <li>Strings</li>
                                                <li>Math</li>
                                                <li>Date</li>
                                                <li>Boolean</li>
                                                <li>Regexp</li>
                                                <li>Exception Handling (Errors)
                                                    <ul className="left">
                                                        <li>try</li>
                                                        <li>catch</li>
                                                        <li>throw</li>
                                                        <li>finally</li>
                                                        <li>Fetch</li>
                                                        <li>Error Object Properties</li>
                                                        <li>JSON (JavaScript Object Notation)</li>
                                                    </ul>
                                                </li>
                                                <li>Object
                                                    <ul className="left">
                                                        <li>Creating a JavaScript Object</li>
                                                        <li>JavaScript Keyword new</li>
                                                        <li>Properties</li>
                                                        <li>Adding Methods to an Object</li>
                                                        <li>Nesting</li>
                                                        <li>Display</li>
                                                        <li>Using an Object Constructor</li>
                                                        <li>Prototypes</li>
                                                        <li>Getter / Setter</li>
                                                        <li>Protection</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Debugging
                                            <ul className="left">
                                                <li>console</li>
                                                <li>developer tools</li>
                                                <li>debugger</li>
                                                <li>try…catch</li>
                                                <li>breakpoints</li>
                                                <li>Network</li>
                                            </ul>
                                        </li>
                                        <li>DOM (Document Object Model)
                                            <ul className="left">
                                                <li>Introduction</li>
                                                <li>Methods</li>
                                                <li>Document</li>
                                                <li>Elements</li>
                                                <li>HTML</li>
                                                <li>Forms</li>
                                                <li>CSS</li>
                                                <li>Animations</li>
                                                <li>Events</li>
                                                <li>EventListener</li>
                                                <li>Navigation</li>
                                                <li>Nodes</li>
                                                <li>Collections</li>
                                                <li>Node Lists</li>
                                            </ul>
                                        </li>
                                        <li>Browser BOM
                                            <ul className="left">
                                                <li>Window</li>
                                                <li>Screen</li>
                                                <li>Location</li>
                                                <li>History</li>
                                                <li>Navigator</li>
                                                <li>Popup</li>
                                                <li>Timing</li>
                                                <li>Cookies</li>
                                            </ul>
                                        </li>
                                        <li>Execution Context and Call Stack</li>
                                        <li>Form Validations
                                            <ul className="left">
                                                <li>Basics of Form Validation</li>
                                                <li>Validating Radio Buttons</li>
                                                <li>Validating Checkboxes</li>
                                                <li>Validating Select Menus</li>
                                                <li>Validating Text & Textareas</li>
                                                <li>Validating Through Regular Expressions: Alphabetical,
                                                    Alphanumeric,
                                                    Email, Date, Image,
                                                    Password, Phone, Zip Code, and so on</li>
                                            </ul>
                                        </li>
                                        <li>Capstone Project 5: JavaScript Project</li>
                                    </ul>
                                    <h6 className="mt-2">7. Async / Await</h6>
                                    <ul className="left">
                                        <li>Callbacks</li>
                                        <li>Asynchronous</li>
                                        <li>Promises</li>
                                        <li>Async / Await</li>
                                    </ul>
                                    <h6 className="mt-2">8. Ajax</h6>
                                    <ul className="left">
                                        <li>Introduction</li>
                                        <li>XMLHttpRequest</li>
                                        <li>Request</li>
                                        <li>Response</li>
                                        <li>JSON</li>
                                        <li>REST API</li>
                                        <li>GET</li>
                                        <li>POST</li>
                                        <li>PUT</li>
                                        <li>DELETE</li>
                                    </ul>
                                    <h6 className="mt-2">9. JSON</h6>
                                    <ul className="left">
                                        <li>Introduction</li>
                                        <li>Syntax</li>
                                        <li>JSON vs XML</li>
                                        <li>Datatypes</li>
                                        <li>parse</li>
                                        <li>stringify</li>
                                        <li>Object</li>
                                        <li>Arrays</li>
                                        <li>Server</li>
                                        <li>Html</li>
                                        <li>JSON vs JSONP</li>
                                    </ul>



                                    <h6 className="mt-2">10. HTML 5</h6>


                                    <ul className="left">
                                        <li>What is HTML5</li>
                                        <li>Overview of HTML5</li>
                                        <li>Browser Support</li>
                                        <li>W3C and Web Hypertext Application Technology Working Group (WHATWG)
                                            Specifications</li>
                                        <li>Overview of Updated New Features</li>
                                        <li>HTML5 Syntax
                                            <ul className="left">
                                                <li>The DOCTYPE</li>
                                                <li>Character Encoding</li>
                                                <li>The &lt;script&gt; tag</li>
                                                <li>The &lt;link&gt; tag</li>
                                                <li>HTML5 Document</li>
                                            </ul>
                                        </li>
                                        <li>Forms
                                            <ul className="left">
                                                <li>email</li>
                                                <li>url</li>
                                                <li>tel</li>
                                                <li>number</li>
                                                <li>range</li>
                                                <li>date</li>
                                                <li>time</li>
                                                <li>datetime</li>
                                                <li>datetime-local</li>
                                                <li>month</li>
                                                <li>week</li>
                                                <li>color</li>
                                            </ul>
                                            <li>Form Elements
                                                <ul className="left">
                                                    <li>datalist</li>
                                                    <li>output</li>
                                                    <li>progress</li>
                                                    <li>meter</li>

                                                    <li>fieldset</li>
                                                </ul>
                                            </li>
                                            <li>New Attributes for &lt;form&gt;
                                                <ul className="left">
                                                    <li>autocomplete</li>
                                                    <li>novalidate</li>
                                                    <li>target</li>
                                                    <li>formaction</li>
                                                    <li>formenctype</li>
                                                    <li>formmethod</li>
                                                    <li>formnovalidate</li>
                                                    <li>formtarget</li>
                                                    <li>accept-charset</li>
                                                </ul>
                                            </li>
                                            <li>New Attributes for &lt;input&gt;
                                                <ul className="left">
                                                    <li>autocomplete</li>
                                                    <li>autofocus</li>
                                                    <li>form</li>
                                                    <li>list</li>
                                                    <li>min and max</li>
                                                    <li>multiple</li>
                                                    <li>pattern (regexp)</li>
                                                    <li>placeholder</li>
                                                    <li>readonly</li>
                                                    <li>required</li>
                                                    <li>step</li>
                                                    <li>size</li>
                                                    <li>maxLength</li>
                                                    <li>inputmode</li>
                                                    <li>formaction</li>
                                                    <li>formenctype</li>
                                                    <li>formmethod</li>
                                                    <li>formnovalidate</li>
                                                    <li>formtarget</li>
                                                </ul>
                                            </li>
                                            <li>Multimedia Elements
                                                <ul className="left">
                                                    <li>Media</li>
                                                    <li>audio</li>
                                                    <li>video</li>

                                                    <li>youtube</li>
                                                </ul>
                                            </li>
                                            <li>Types of Elements
                                                <ul className="left">
                                                    <li>Semantic Elements</li>
                                                    <li>Structural Elements</li>
                                                </ul>
                                            </li>
                                            <li>HTML5 New Elements
                                                <ul className="left">
                                                    <li>article</li>
                                                    <li>section</li>
                                                    <li>header</li>
                                                    <li>footer</li>
                                                    <li>nav</li>
                                                    <li>aside</li>
                                                    <li>main</li>
                                                    <li>figcaption</li>
                                                    <li>figure</li>
                                                    <li>time</li>
                                                    <li>mark</li>
                                                    <li>details</li>
                                                    <li>summary</li>
                                                    <li>progress</li>
                                                    <li>output</li>
                                                    <li>address</li>
                                                    <li>dialog</li>
                                                    <li>template</li>
                                                    <li>slot</li>
                                                    <li>wbr</li>
                                                    <li>hgroup</li>
                                                </ul>
                                            </li>
                                            <li>Migration from HTML4 to HTML5
                                                <ul className="left">
                                                    <li>HTML5 Browser Support</li>
                                                    <li>HTML5 Elements as Block Elements</li>
                                                    <li>Adding New Elements to HTML</li>
                                                    <li>Problem with Internet Explorer</li>
                                                    <li>Complete Shiv Solution</li>
                                                    <li>HTML5 Skeleton</li>
                                                </ul>
                                            </li>
                                            <li>HTML5 Deprecated Tags
                                                <ul className="left">
                                                    <li>center</li>
                                                    <li>font</li>
                                                    <li>basefont</li>
                                                    <li>s</li>
                                                    <li>strike</li>
                                                    <li>u</li>
                                                    <li>applet</li>
                                                    <li>isindex</li>
                                                    <li>acronym</li>
                                                    <li>frame</li>
                                                    <li>frameset</li>
                                                    <li>noframes</li>
                                                    <li>embed</li>
                                                    <li>marquee</li>
                                                    <li>big</li>
                                                    <li>dir</li>
                                                    <li>spacer</li>
                                                    <li>tt</li>
                                                </ul>
                                            </li>
                                            <li>HTML5 Deprecated Attributes
                                                <ul className="left">
                                                    <li>align - caption, iframe, img, input, legend, table, hr, div, h1,
                                                        h2,
                                                        h3,
                                                        h4, h5, h6, p, td,
                                                        th, and tr.</li>
                                                    <li>hspace - img</li>
                                                    <li>vspace - img</li>
                                                    <li>width - hr, table, td, th, pre, img.</li>
                                                    <li>height - table, td, th, pre, img.</li>
                                                    <li>background - body</li>
                                                    <li>bgcolor - table, tr, td, th and body.</li>
                                                    <li>rules - table</li>
                                                    <li>border - table, img</li>
                                                    <li>cellpadding - table</li>
                                                    <li>cellspacing - table</li>
                                                    <li>size - hr</li>
                                                    <li>type - li, ol and ul</li>
                                                    <li>noshade - hr</li>
                                                    <li>char</li>
                                                    <li>charoff</li>
                                                    <li>color, face - font</li>
                                                    <li>marginwidth, marginheight - iframe, body</li>
                                                    <li>nowrap - td, th</li>
                                                    <li>size - font, hr</li>
                                                    <li>start - ol</li>
                                                    <li>valign - td, th, tr</li>
                                                </ul>
                                            </li>
                                            <li>Web Storage
                                                <ul className="left">
                                                    <li>Local Storage</li>
                                                    <li>Session Storage</li>
                                                </ul>
                                            </li>
                                            <li>Web SQL</li>
                                            <li>Web Workers</li>
                                            <li>MathML</li>
                                            <li>Drag and Drop API</li>
                                            <li>Canvas Overview
                                                <ul className="left">
                                                    <li>Lines</li>
                                                    <li>Curves</li>
                                                    <li>font</li>
                                                    <li>Image</li>
                                                    <li>Paths</li>
                                                    <li>Shapes</li>
                                                    <li>Color, Gradients</li>
                                                </ul>
                                            </li>
                                            <li>SVG
                                                <ul className="left">
                                                    <li>What is SVG?</li>
                                                    <li>SVG Advantages</li>
                                                    <li>Differences Between SVG and Canvas</li>
                                                    <li>Rectangle</li>
                                                    <li>Circle</li>
                                                    <li>Ellipse</li>
                                                    <li>Line</li>
                                                    <li>Polygon</li>
                                                </ul>
                                            </li></li>
                                    </ul>



                                    <h6 className="mt-2">11. Bootstrap / Tailwind CSS</h6>


                                    <ul className="left">
                                        <li>What is Bootstrap?</li>
                                        <li>Bootstrap History</li>
                                        <li>Why Use Bootstrap?</li>
                                        <li>What Does Bootstrap Include?</li>
                                        <li>HTML File</li>
                                        <li>Adding Bootstrap to Your Web Pages</li>
                                        <li>Downloading Bootstrap</li>
                                        <li>Bootstrap CDN</li>
                                        <li>Put Your HTML Elements Inside Containers</li>
                                        <li>Bootstrap Browser/Device Support</li>
                                        <li>Layout
                                            <ul className="left">
                                                <li>Breakpoints</li>
                                                <li>Containers</li>
                                                <li>Grid</li>
                                                <li>Columns</li>
                                                <li>Gutters</li>
                                                <li>Utilities</li>
                                                <li>Z-index</li>
                                                <li>CSS Grid</li>
                                            </ul>
                                        </li>
                                        <li>Content
                                            <ul className="left">
                                                <li>Reboot</li>
                                                <li>Typography</li>
                                                <li>Images</li>
                                                <li>Tables</li>
                                                <li>Figures</li>
                                            </ul>
                                        </li>
                                        <li>Forms
                                            <ul className="left">
                                                <li>Overview</li>
                                                <li>Form control</li>
                                                <li>Select</li>
                                                <li>Checks & radios</li>
                                                <li>Range</li>
                                                <li>Input group</li>
                                                <li>Floating labels</li>
                                                <li>Layout</li>
                                                <li>Validation</li>
                                            </ul>
                                        </li>
                                        <li>Components
                                            <ul className="left">
                                                <li>Accordion</li>
                                                <li>Alerts</li>
                                                <li>Badge</li>
                                                <li>Breadcrumb</li>
                                                <li>Buttons</li>
                                                <li>Button group</li>
                                                <li>Card</li>
                                                <li>Carousel</li>
                                                <li>Close button</li>
                                                <li>Collapse</li>
                                                <li>Dropdowns</li>
                                                <li>List group</li>
                                                <li>Modal</li>
                                                <li>Navbar</li>
                                                <li>Navs & tabs</li>
                                                <li>Offcanvas</li>
                                                <li>Pagination</li>
                                                <li>Placeholders</li>
                                                <li>Popovers</li>
                                                <li>Progress</li>
                                                <li>Scrollspy</li>
                                                <li>Spinners</li>
                                                <li>Toasts</li>
                                                <li>Tooltips</li>
                                            </ul>
                                        </li>
                                        <li>Helpers
                                            <ul className="left">
                                                <li>Clearfix</li>
                                                <li>Color & background</li>
                                                <li>Colored links</li>
                                                <li>Focus ring</li>
                                                <li>Icon link</li>
                                                <li>Position</li>
                                                <li>Ratio</li>
                                                <li>Stacks</li>
                                                <li>Stretched link</li>
                                                <li>Text truncation</li>
                                                <li>Vertical rule</li>
                                                <li>Visually hidden</li>
                                            </ul>
                                        </li>
                                        <li>Utilities
                                            <ul className="left">
                                                <li>API</li>
                                                <li>Background</li>
                                                <li>Borders</li>
                                                <li>Colors</li>
                                                <li>Display</li>
                                                <li>Flex</li>
                                                <li>Float</li>
                                                <li>Interactions</li>
                                                <li>Link</li>
                                                <li>Object fit</li>
                                                <li>Opacity</li>
                                                <li>Overflow</li>
                                                <li>Position</li>
                                                <li>Shadows</li>
                                                <li>Sizing</li>
                                                <li>Spacing</li>
                                                <li>Text</li>
                                                <li>Vertical align</li>
                                                <li>Visibility</li>
                                                <li>Z-index</li>
                                            </ul>
                                        </li>
                                        <li>Extend
                                            <ul className="left">
                                                <li>Approach</li>
                                                <li>Icons</li>
                                            </ul>
                                        </li>
                                        <li>Capstone Project 5: Bootstrap / Tailwind CSS Responsive Project</li>
                                    </ul>
                                    <h6 className="mt-2">12. Social Media Integrations</h6>
                                    <h6 className="mt-2">13. Search Engine Optimization</h6>
                                    <h6 className="mt-2">14. Google Analytics Integration</h6>
                                    <h6 className="mt-2">15. Capstone Project 1: HTML & CSS Project</h6>
                                    <h6 className="mt-2">16. Capstone Project 2: HTML5 & CSS3 Responsive Project with Floats</h6>
                                    <h6 className="mt-2">17. Capstone Project 3: HTML5 & CSS3,4 Responsive Project with Flexbox</h6>
                                    <h6 className="mt-2">18. Capstone Project 4: JavaScript Project</h6>
                                    <h6 className="mt-2">19. Capstone Project 5: Bootstrap / Tailwind CSS Responsive Project</h6>
                                    <h6 className="mt-2">20. Domain Registration & Web Hosting</h6>
                                    <h6 className="mt-2">21. ECMAScript:</h6>

                                    <ul className="left">
                                        <li>Introduction</li>
                                        <li>Environment</li>
                                        <li>Syntax</li>
                                        <li>Console</li>
                                        <li>Scripting language vs Programming language
                                            <ul className="left">
                                                <li>Execution process</li>
                                                <li>No Intermediate Output</li>
                                                <li>Feedback and Debugging</li>
                                                <li>Usage</li>
                                            </ul>
                                        </li>
                                        <li>Variables
                                            <ul className="left">
                                                <li>Automatically</li>
                                                <li>Using var (js)</li>
                                                <li>using let (es6)</li>
                                                <li>using const (es6)</li>
                                                <li>Differences between var, let and const</li>
                                                <li>Hoisting</li>
                                            </ul>
                                        </li>
                                        <li>Operators
                                            <ul className="left">
                                                <li>Arithmetic</li>
                                                <li>Assignment</li>
                                                <li>String</li>
                                                <li>Type</li>
                                                <li>Comparison</li>
                                                <li>Logical</li>
                                            </ul>
                                        </li>
                                        <li>Decision Making (Conditions)
                                            <ul className="left">
                                                <li>If statement</li>
                                                <li>? ternary operator</li>
                                                <li>If..else statement</li>
                                                <li>If..else if..else statement</li>
                                                <li>switch statement</li>
                                            </ul>
                                        </li>
                                        <li>Loops
                                            <ul className="left">
                                                <li>for</li>
                                                <li>While</li>
                                                <li>do..While</li>
                                                <li>for/in</li>
                                                <li>for/of</li>
                                                <li>break</li>
                                                <li>continue</li>
                                            </ul>
                                        </li>
                                        <li>Functions
                                            <ul className="left">
                                                <li>Anonymous</li>
                                                <li>Shadowing vs Closures</li>
                                                <li>Arrow functions
                                                    <ul className="left">
                                                        <li>Parameters</li>
                                                        <li>Implicit return</li>
                                                        <li>this</li>
                                                        <li>No arguments</li>
                                                        <li>No new</li>
                                                        <li>No prototype property</li>
                                                        <li>Returning Object Literal</li>
                                                        <li>Array methods</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Primitive Data Types
                                            <ul className="left">
                                                <li>Number</li>
                                                <li>String</li>
                                                <li>Boolean</li>
                                                <li>Null</li>
                                                <li>Undefined</li>
                                                <li>Symbol</li>
                                                <li>BigInt</li>
                                            </ul>
                                        </li>
                                        <li>Literals
                                            <ul className="left">
                                                <li>Numeric</li>
                                                <li>Integers</li>
                                                <li>Floating-Points</li>
                                                <li>String</li>
                                                <li>Boolean</li>
                                                <li>Objects</li>
                                                <li>Array</li>
                                                <li>Template</li>
                                                <li>Regexp or Pattern</li>
                                                <li>Null</li>
                                                <li>Undefined</li>
                                            </ul>
                                        </li>
                                        <li>Rest vs Spread Operator</li>
                                        <li>Destructuring</li>
                                        <li>Modules</li>
                                        <li>Iterator
                                            <ul className="left">
                                                <li>String</li>
                                                <li>Array</li>
                                                <li>Map</li>
                                                <li>Set</li>
                                            </ul>
                                        </li>
                                        <li>Collections
                                            <ul className="left">
                                                <li>Map</li>
                                                <li>Set</li>
                                                <li>WeakMap</li>
                                                <li>WeakSet</li>
                                            </ul>
                                        </li>
                                        <li>Generators</li>
                                        <li>Reflect API</li>
                                        <li>Proxy API</li>
                                        <li>Classes (OOPS: Object-Oriented Programming System)
                                            <ul className="left">
                                                <li>Class Declaration</li>
                                                <li>Class Expression</li>
                                                <li>Constructor</li>
                                                <li>Class Methods</li>
                                                <li>Static Methods</li>
                                                <li>Setters and Getters</li>
                                                <li>instanceof operator</li>
                                                <li>Class Inheritance</li>
                                                <li>Super Keyword</li>
                                                <li>Method Overriding</li>
                                                <li>Private Fields and Methods</li>
                                                <li>Class Fields</li>
                                                <li>Abstract Class</li>
                                                <li>Mixins</li>
                                                <li>Sub-Classing Built-in Objects</li>
                                                <li>Encapsulation</li>
                                                <li>Polymorphism</li>
                                                <li>Mutable Objects</li>
                                            </ul>
                                        </li>
                                        <li>Versions and New Features
                                            <ul className="left">
                                                <li>EcmaScript 1997 : ECMAScript 1(ES1) was standardized by Ecma
                                                    International, based on JavaScript 1.1, to create a formal,
                                                    consistent
                                                    specification for the language.</li>
                                                <li>ES2 : 1998</li>
                                                <li>ES3 : 1999</li>
                                                <li>ES4 : Development abandoned</li>
                                                <li>ES5 : 2009 </li>
                                                <li>ES5.1 : 2011</li>
                                                <li>ES6 : 2015</li>
                                                <li>ES7 : 2016</li>
                                                <li>ES8 : 2017</li>
                                                <li>ES9 : 2018</li>
                                                <li>ES10 : 2019</li>
                                                <li>ES11 : 2020</li>
                                                <li>ES12 : 2021</li>
                                                <li>ES13 : 2022</li>
                                                <li>ES14 : 2023 </li>
                                                <li>ES15 : 2024</li>
                                                <li>ES16 : 2025</li>
                                            </ul>
                                        </li>
                                        <li><b>Content will be updated as per latest updates.</b></li>
                                    </ul>





                                    <h6 className="mt-2">22.
                                        TypeScript:</h6>


                                    <ul className="left">
                                        <li>Introduction</li>
                                        <li>Environment</li>
                                        <li>Syntax</li>
                                        <li>Variables & Data Types</li>
                                        <li>Operators</li>
                                        <li>Decision Making (Conditions)</li>
                                        <li>Loops</li>
                                        <li>Functions</li>
                                        <li>Arrays & Tuples</li>
                                        <li>Objects & Interfaces</li>
                                        <li>Advanced Types</li>
                                        <li>Classes (OOPS: Object-Oriented Programming System)</li>
                                        <li>Generics</li>
                                        <li>Modules & Namespaces</li>
                                        <li>Iterators & Collections</li>
                                        <li>Decorators</li>
                                        <li>Asynchronous Programming</li>
                                        <li>TypeScript Utility Types</li>
                                        <li>Reflect API & Proxy API</li>
                                        <li>Advanced TypeScript Features</li>
                                        <li>TypeScript and Frontend Frameworks</li>
                                        <li>TypeScript and Backend Development</li>
                                        <li>Unit Testing in TypeScript</li>
                                        <li>TypeScript Best Practices</li>
                                        <li>TypeScript Versions and Features</li>
                                    </ul>


                                    <h5 className="mt-2">23. SASS,
                                        SCSS:</h5>


                                    <ul className="left">
                                        <li>Variables</li>
                                        <li>Nesting</li>
                                        <li>Partials</li>
                                        <li>Modules</li>
                                        <li>Mixins</li>
                                        <li>Extend/Inheritance</li>
                                        <li>Operators</li>
                                    </ul>

                                    <h5 className="mt-2">24. React(JavaScript & TypeScript) / Material UI / Jest / Redux

                                        Next.js (React Framework)*****:</h5>


                                    <ul className="left">
                                        <li>What is React?</li>
                                        <li>React vs Other Frontend Frameworks</li>
                                        <li>Introduction</li>
                                        <li>Library</li>
                                        <li>Collection of functions</li>
                                        <li>Difference between Functions and Utilities</li>
                                        <li>Classes</li>
                                        <li>Single-Page Application (SPA)
                                            <ul className="left">
                                                <li>What is a SPA?</li>
                                                <li>How SPAs Work</li>
                                                <li>Initial Load</li>
                                                <li>Dynamic Updates</li>
                                                <li>Benefits of SPAs</li>
                                            </ul>
                                        </li>
                                        <li>Why React?
                                            <ul className="left">
                                                <li>Declarative UI</li>
                                                <li>Component-based</li>
                                                <li>Key Features of React</li>
                                            </ul>
                                        </li>
                                        <li>React History</li>
                                        <li>React 19: New Features & Updates
                                            <ul className="left">
                                                <li>React Compiler for Performance Optimizations</li>
                                                <li>New Hooks and Enhanced useState Hook</li>
                                                <li>React Actions API (Alternative to useEffect)</li>
                                                <li>New Suspense and Streaming Improvements</li>
                                                <li>New React Optimistic Updates API</li>
                                                <li>Refactor useEffect with React Actions</li>
                                                <li>Automatic React Server Components (RSC) Enhancements</li>
                                                <li>React Profiling and Debugging Updates</li>
                                            </ul>
                                        </li>
                                        <li>DOM in React
                                            <ul className="left">
                                                <li>HTML DOM Vs JavaScript DOM</li>
                                                <li>Real (HTML / JS) DOM</li>
                                                <li>Virtual DOM</li>
                                                <li>Difference between Real DOM and Virtual DOM</li>
                                            </ul>
                                        </li>
                                        <li>Setting Up the React Environment
                                            <ul className="left">
                                                <li>npx and npm ecosystem</li>
                                                <li>Setting up a React development environment (Node.js, npm, or
                                                    Yarn)</li>
                                                <li>Creating a React project (Vite / Next)</li>
                                                <li>Understanding React Project Structure</li>
                                                <li>Running a React App</li>
                                                <li>Flow of execution</li>
                                                <li>React’s Strict Mode in Development</li>
                                            </ul>
                                        </li>
                                        <li>JSX (JavaScript XML)
                                            <ul className="left">
                                                <li>What is JSX?</li>
                                                <li>Syntax</li>
                                                <li>Using JavaScript inside JSX</li>
                                                <li>JSX and Babel Compilation</li>
                                                <li>Expressions in JSX</li>
                                                <li>Handling attributes in JSX (className, htmlFor)</li>
                                            </ul>
                                        </li>
                                        <li>Components in React
                                            <ul className="left">
                                                <li>What are Components?</li>
                                                <li>Functional component</li>
                                                <li>Class Component</li>
                                                <li>Nested Components</li>
                                                <li>Components in Files</li>
                                                <li>Writing and Exporting Components</li>
                                                <li>Importing Components</li>
                                            </ul>
                                        </li>
                                        <li>Props
                                            <ul className="left">
                                                <li>What are Props?</li>
                                                <li>Passing Props to Components</li>
                                                <li>Default Props</li>
                                                <li>Prop Types for Type Checking</li>
                                                <li>Destructuring Props</li>
                                                <li>Props vs State</li>
                                            </ul>
                                        </li>
                                        <li>Event Handling
                                            <ul className="left">
                                                <li>Introduction to Event Handling</li>
                                                <li>Basic Event Handling in JSX</li>
                                                <li>Passing Arguments to Event Handlers</li>
                                                <li>Preventing Default Behavior</li>
                                                <li>Stopping Event Bubbling & Event Capturing</li>
                                            </ul>
                                        </li>
                                        <li>State Management in React
                                            <ul className="left">
                                                <li>What is State?</li>
                                                <li>Managing Local State with useState</li>
                                                <li>Updating State Correctly</li>
                                                <li>Handling Complex State Objects</li>
                                                <li>State Immutability in React</li>
                                            </ul>
                                        </li>
                                        <li>React Hooks (Deep Dive)
                                            <ul className="left">
                                                <li>What are Hooks?</li>
                                                <li>Rules of Hooks</li>
                                                <li>Built-in Hooks:
                                                    <ul className="left">
                                                        <li>useState</li>
                                                        <li>useEffect</li>
                                                        <li>useRef</li>
                                                        <li>useMemo</li>
                                                        <li>useCallback</li>
                                                        <li>useReducer</li>
                                                        <li>useContext</li>
                                                        <li>useLayoutEffect</li>
                                                    </ul>
                                                </li>
                                                <li>New Hooks in React 19</li>
                                            </ul>
                                        </li>
                                        <li>React Router
                                            <ul className="left">
                                                <li>Installing and Setting Up React Router</li>
                                                <li>BrowserRouter, HashRouter, and MemoryRouter</li>
                                                <li>Defining Routes with Route Component</li>
                                                <li>Using Link and NavLink for Navigation</li>
                                            </ul>
                                        </li>
                                        <li>Context API and Global State Management
                                            <ul className="left">
                                                <li>Why Use Context API?</li>
                                                <li>Creating a Context with createContext</li>
                                                <li>Consuming Context with useContext</li>
                                                <li>Updating Context Data</li>
                                                <li>Context API vs Redux</li>
                                            </ul>
                                        </li>
                                        <li>React Performance Optimization
                                            <ul className="left">
                                                <li>Memoization with useMemo</li>
                                                <li>Avoiding Unnecessary Re-renders with React.memo</li>
                                                <li>useCallback for Optimizing Functions</li>
                                                <li>New React 19 Optimistic UI Updates</li>
                                            </ul>
                                        </li>
                                        <li>Redux & State Management (Advanced)
                                            <ul className="left">
                                                <li>Understanding Redux</li>
                                                <li>Setting Up Redux in a React Project</li>
                                                <li>Redux Core Concepts:
                                                    <ul className="left">
                                                        <li>Actions</li>
                                                        <li>Reducers</li>
                                                        <li>Store</li>
                                                        <li>Using useSelector & useDispatch</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>React with Next.js
                                            <ul className="left">
                                                <li>Introduction to Next.js</li>
                                                <li>Next.js vs React</li>
                                                <li>Server-side Rendering (SSR)</li>
                                                <li>Static Site Generation (SSG)</li>
                                                <li>Incremental Static Regeneration (ISR)</li>
                                            </ul>
                                        </li>
                                        <li>React with TypeScript
                                            <ul className="left">
                                                <li>Why Use TypeScript with React?</li>
                                                <li>Setting Up TypeScript in React</li>
                                                <li>Typing Props & State</li>
                                                <li>Using TypeScript with Redux</li>
                                            </ul>
                                        </li>
                                        <li>Deploying React Applications
                                            <ul className="left">
                                                <li>Preparing for Production</li>
                                                <li>Optimizing React App Performance</li>
                                                <li>Deploying on AWS, GCP, Jenkins</li>
                                                <li>CI/CD for React Applications</li>
                                            </ul>
                                        </li>
                                    </ul>



                                    <h6 className="mt-2">25. CRUD (Create, Read, Update, Delete)</h6>
                                    <h6 className="mt-2">26. RESTful APIs to fetch, send, and manipulate data from
                                        the server
                                    </h6>
                                    <h6 className="mt-2">27. JSON Web Token (JWT)</h6>
                                    <h6 className="mt-2">28. User Authentication and Authorization</h6>
                                    <h6 className="mt-2">29. Developer Tools and Debugging</h6>
                                    <h6 className="mt-2">30. AI: ChatGPT, CoPilot, Gemini</h6>
                                    <h6 className="mt-2">31. Postman</h6>
                                    <h6 className="mt-2">32. Figma</h6>
                                    <h6 className="mt-2">33. Version Control & Project Management Tools</h6>
                                    <ul className='left'>
                                        <li>1. Bitbucket / GitHub (Version Control with Git)</li>
                                        <li>2. Jira</li>
                                        <li>3. Confluence</li>
                                    </ul>
                                    <h6 className="mt-2">34. Generative AI</h6>
                                    <h6 className="mt-2">35. Capstone Project 7: React</h6>
                                    <h6 className="mt-2">36. Deployment: GCP / AWS Cloud / Jenkins</h6>
                                    <h6 className="mt-2">37. Node.js / 38. Express JS</h6>


                                    <ul class="left">
                                        <li>
                                            <strong>Introduction to
                                                Node.js</strong>
                                            <ul>
                                                <li>What is Node.js?</li>
                                                <li>JavaScript runtime
                                                    environment</li>
                                                <li>Asynchronous and
                                                    event-driven programming
                                                </li>
                                                <li>Setting up Node.js and npm
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Node.js Basics</strong>
                                            <ul>
                                                <li>Core modules in Node.js</li>
                                                <li>Creating and running Node.js
                                                    scripts</li>
                                                <li>Working with the file system
                                                    (fs module)</li>
                                                <li>Using built-in modules
                                                    (http, fs, path, etc.)</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Asynchronous JavaScript
                                                (Callbacks, Promises, and
                                                Async/Await)</strong>
                                            <ul>
                                                <li>Understanding asynchronous
                                                    code</li>
                                                <li>Callback functions and
                                                    callback hell</li>
                                                <li>Using Promises for cleaner
                                                    asynchronous code</li>
                                                <li>Async/Await syntax for
                                                    asynchronous operations</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Node Package Manager
                                                (npm)</strong>
                                            <ul>
                                                <li>Managing packages and
                                                    dependencies</li>
                                                <li>Installing and updating
                                                    packages</li>
                                                <li>Creating and publishing your
                                                    own packages</li>
                                                <li>Package.json and
                                                    package-lock.json files</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Building a RESTful API with
                                                Node.js</strong>
                                            <ul>
                                                <li>Creating a basic HTTP server
                                                </li>
                                                <li>Handling HTTP requests and
                                                    responses</li>
                                                <li>RESTful architecture
                                                    principles</li>
                                                <li>Routing and middleware in
                                                    Express.js</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Express.js
                                                Framework</strong>
                                            <ul>
                                                <li>Introduction to Express.js
                                                </li>
                                                <li>Setting up an Express.js
                                                    application</li>
                                                <li>Handling routes and
                                                    endpoints</li>
                                                <li>Middleware and request
                                                    processing</li>
                                                <li>Using Express.js templates
                                                    and views</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Database Integration with
                                                Node.js</strong>
                                            <ul>
                                                <li>Connecting to databases
                                                    (e.g., MongoDB, MySQL)</li>
                                                <li>Using database drivers and
                                                    libraries</li>
                                                <li>Executing database queries
                                                </li>
                                                <li>CRUD operations with Node.js
                                                    and databases</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Authentication and
                                                Authorization</strong>
                                            <ul>
                                                <li>Implementing user
                                                    authentication</li>
                                                <li>Token-based authentication
                                                    with JSON Web Tokens (JWT)
                                                </li>
                                                <li>Securing API routes and
                                                    endpoints</li>
                                                <li>User roles and permissions
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Middleware in
                                                Express.js</strong>
                                            <ul>
                                                <li>Creating custom middleware
                                                </li>
                                                <li>Using third-party middleware
                                                    (e.g., body-parser,
                                                    cookie-parser)</li>
                                                <li>Authentication middleware
                                                </li>
                                                <li>Error handling middleware
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Real-time Web Applications
                                                with Socket.io</strong>
                                            <ul>
                                                <li>Introduction to WebSocket
                                                    and Socket.io</li>
                                                <li>Creating a real-time chat
                                                    application</li>
                                                <li>Broadcasting events and
                                                    messages</li>
                                                <li>Handling real-time updates
                                                    in Node.js</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Testing and Debugging
                                                Node.js Applications</strong>
                                            <ul>
                                                <li>Unit testing with frameworks
                                                    like Mocha and Chai</li>
                                                <li>Debugging Node.js
                                                    applications with built-in
                                                    tools</li>
                                                <li>Using linters for code
                                                    quality</li>
                                                <li>Performance profiling and
                                                    optimization</li>
                                            </ul>
                                        </li>

                                        <li>
                                            <strong>Deploying Node.js
                                                Applications</strong>
                                            <ul>
                                                <li>Deploying on AWS, GCP, Jenkins</li>
                                                <li>Configuring production-ready
                                                    environments</li>
                                                <li>Load balancing and scaling
                                                    Node.js apps</li>
                                                <li>Monitoring and error
                                                    tracking in production</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h6 className="mt-2">39. MongoDB</h6>
                                    <ul className="left">
                                        <li>MongoDB Get Started</li>
                                        <li>MongoDB Create DB</li>
                                        <li>MongoDB Collection</li>
                                        <li>MongoDB Insert</li>
                                        <li>MongoDB Find</li>
                                        <li>MongoDB Query</li>
                                        <li>MongoDB Sort</li>
                                        <li>MongoDB Delete</li>
                                        <li>MongoDB Drop Collection</li>
                                        <li>MongoDB Update</li>
                                        <li>MongoDB Limit</li>
                                    </ul>
                                    <h6 className="mt-2">40. MySQL</h6>
                                    <ul className="left">
                                        <li>MySQL Get Started</li>
                                        <li>MySQL Create Database</li>
                                        <li>MySQL Create Table</li>
                                        <li>MySQL Insert</li>
                                        <li>MySQL Select</li>
                                        <li>MySQL Where</li>
                                        <li>MySQL Order By</li>
                                        <li>MySQL Delete</li>
                                        <li>MySQL Drop Table</li>
                                        <li>MySQL Update</li>
                                        <li>MySQL Limit</li>
                                        <li>MySQL Join</li>
                                    </ul>
                                   
                                    <h6 className="mt-2">41. Capstone Project 8: MERN</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            { /* Footer */}
            < footer id="footer-part" >
                <div className="footer-top pt-40 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer-about mt-40">
                                    <div className="logo">
                                        <a href="index.html" aria-label="Siva Soft Home Page">
                                            <img src="images/sivasoft-logo.png" alt="Siva Soft logo" className="sivasoft-logo" />
                                            <span className="sivasoft-footer-text">Siva Soft</span>
                                        </a> </div> <p> SIVASOFT TECHNOLOGIES PRIVATE LIMITED (Training &
                                            Development) is an ISO 9001: 2015 certified company: No 1 Training Company with Dedicated Trainers and Professionals from Top MNCs like Amazon, Google, Microsoft, Infosys... </p>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="footer-address mt-40">
                                    <div className="footer-title pb-25">
                                        <h6> Contact Us </h6>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-geo-alt" aria-label="Address"></i>
                                            </div>
                                            <div className="cont">
                                                <p> # 416, 417, Annapurna Block, Aditya
                                                    Enclave, Near Ameerpet Metro, Hyderabad, India</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-whatsapp" aria-label="WhatsApp"></i>
                                            </div>
                                            <div className="cont">
                                                <p> +91 63029 64834</p>
                                                <p> +91 92481 53330</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-envelope" aria-label="Email"></i>
                                            </div>
                                            <div className="cont">
                                                <p> sivasoft@sivasoft.in</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-copyright pt-10 pb-25">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright text-md-left text-center pt-15">
                                    <p> &copy; 2012 - {new Date().getFullYear()} SIVASOFT TECHNOLOGIES PRIVATE LIMITED
                                        (Training & Development) is an ISO 9001: 2015 certified company.All Rights Reserved.Logo, Images, and Content are the trademark of their respective owners. {title} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer >
            <a
                href="https://wa.me/916302964834"
                aria-label="WhatsApp"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-btn btn btn-success d-flex align-items-center justify-content-center"
                style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
            >
                <BsWhatsapp size={24} color="white" />
            </a>

            <ScrollToTopButton />
        </div >
    );
};

export default LocationPage;
